<template>
  <div class="menu" v-fixed="{ top: 600 }">
    <!-- <div class="menu-circle" @click="handleService">
      <img class="menu-circle-img1" src="../assets/images/customer.png" />
      <img class="menu-circle-img2" src="../assets/images/customer_gray.png" />
    </div> -->
    <div class="menu-circle">
      <img class="menu-circle-img1" src="../assets/images/phone.png" />
      <img class="menu-circle-img2" src="../assets/images/phone_gray.png" />
      <div class="menu-circle-tooltip">
        <div class="menu-circle-tooltip-content">
          <img
            class="menu-circle-tooltip-content-img"
            src="../assets/images/phone2.png"
          />
          <span>024-23306292</span>
        </div>
        <div class="menu-circle-tooltip-triangle"></div>
      </div>
    </div>
    <div class="menu-circle" @click="toTopHandle">
      <img class="menu-circle-img1" src="../assets/images/top.png" />
      <img class="menu-circle-img2" src="../assets/images/top_gray.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RightMenu",
  methods: {
    handleService() {
      window.open('https://cs.lituict.net/index/index/home?business_id=11&groupid=0&theme=597ef7')
    },
    toTopHandle() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  position: fixed;
  right: 4.1%;
  &-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(37, 43, 58, 0.12);
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
    &-img1 {
      display: none;
    }
    &-img2 {
      display: block;
    }
    &-tooltip {
      position: absolute;
      top: 0;
      left: -222px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(37, 43, 58, 0.1);
      border-radius: 10px;
      padding: 15px;
      opacity: 0;
      transition: all 0.3s;
      * {
        user-select: text;
      }
      &-content {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: #0081ff;
        &-img {
          width: 21px;
          height: 18px;
          margin-right: 16px;
        }
      }
      &-triangle {
        position: absolute;
        top: 14px;
        right: -9px;
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid #ffffff;
      }
    }
    &:hover {
      background: #0081ff;
      .menu-circle-tooltip {
        opacity: 1;
      }
      .menu-circle-img1 {
        display: block;
      }
      .menu-circle-img2 {
        display: none;
      }
    }
  }
}
</style>