import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a5126d8 = () => interopDefault(import('../pages/about/about.vue' /* webpackChunkName: "" */))
const _33d8bfba = () => interopDefault(import('../pages/contact/contact.vue' /* webpackChunkName: "" */))
const _10a73a5e = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "" */))
const _4dc4b252 = () => interopDefault(import('../pages/information/information.vue' /* webpackChunkName: "" */))
const _56e55950 = () => interopDefault(import('../pages/product/product.vue' /* webpackChunkName: "" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/about",
    component: _1a5126d8,
    name: "about___zh"
  }, {
    path: "/contact/contact",
    component: _33d8bfba,
    name: "contact___zh"
  }, {
    path: "/en/",
    component: _10a73a5e,
    name: "index___en"
  }, {
    path: "/information/information",
    component: _4dc4b252,
    name: "information___zh"
  }, {
    path: "/product/product",
    component: _56e55950,
    name: "product___zh"
  }, {
    path: "/tw/",
    component: _10a73a5e,
    name: "index___tw"
  }, {
    path: "/vi/",
    component: _10a73a5e,
    name: "index___vi"
  }, {
    path: "/en/about/about",
    component: _1a5126d8,
    name: "about___en"
  }, {
    path: "/en/contact/contact",
    component: _33d8bfba,
    name: "contact___en"
  }, {
    path: "/en/information/information",
    component: _4dc4b252,
    name: "information___en"
  }, {
    path: "/en/product/product",
    component: _56e55950,
    name: "product___en"
  }, {
    path: "/tw/about/about",
    component: _1a5126d8,
    name: "about___tw"
  }, {
    path: "/tw/contact/contact",
    component: _33d8bfba,
    name: "contact___tw"
  }, {
    path: "/tw/information/information",
    component: _4dc4b252,
    name: "information___tw"
  }, {
    path: "/tw/product/product",
    component: _56e55950,
    name: "product___tw"
  }, {
    path: "/vi/about/about",
    component: _1a5126d8,
    name: "about___vi"
  }, {
    path: "/vi/contact/contact",
    component: _33d8bfba,
    name: "contact___vi"
  }, {
    path: "/vi/information/information",
    component: _4dc4b252,
    name: "information___vi"
  }, {
    path: "/vi/product/product",
    component: _56e55950,
    name: "product___vi"
  }, {
    path: "/",
    component: _10a73a5e,
    name: "index___zh"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
