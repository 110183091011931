<template>
  <div id="root__dom">
    <div id="app">
      <div class="header" style="background-color: #fff" v-fixed="{ top: 0 }">
        <img
          style="margin-left: 56px; width: 149px; height: 44px"
          src="/img/header/Group.svg"
          alt=""
        />
        <ul class="title">
          <a v-for="(item, index) in title" :key="item.id">
            <li @click="handleGoPage(item, index)">
              <div v-if="nowIndex == index" class="bottom"></div>
              <span :class="{ active: nowIndex == index }">{{
                $t(item.title)
              }}</span>
            </li>
          </a>
        </ul>
        <!-- <div class="right">
          <ul>
            <li
              v-for="(locale, i) in showLocales"
              :key="i"
              :class="{ active: locale.code === $i18n.locale }"
            >
              <nuxt-link
                @click.native="switchLang(locale.code)"
                :to="switchLocalePath(locale.code)"
              >
                {{ $t("app." + locale.code) }}
              </nuxt-link>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="header1"></div>
    </div>
    <div class="content__view">
      <Nuxt />
      <div class="footer">
        <div class="footer-top">
          <div class="footer-top-form">
            <img
              class="footer-top-form-logo"
              src="/img/footer/footer_logo.svg"
            />
            <div class="footer-top-form-info">
              <span>请留下您的电话 专属顾问会尽快联系您！</span>
              <div class="footer-top-form-info-input">
                <el-input v-model="form.name" placeholder="姓名"></el-input>
                <el-input
                  v-model="form.phone"
                  placeholder="联系方式"
                ></el-input>
                <el-input
                  v-model="form.content"
                  placeholder="您的需求"
                ></el-input>
                <div
                  class="footer-top-form-info-input-emit"
                  @click="saveInfoHandle"
                >
                  提交
                </div>
              </div>
            </div>
          </div>
          <div class="footer-top-line"></div>
          <div class="footer-top-address">
            <div>
              <p>
                Copyright © 2022 Liaoning Litu Technology Co., Ltd All Rights
                Reserved.
              </p>
              <p>辽ICP备2022006137号</p>
            </div>
            <!-- <div>
              <p style="font-weight: 500">024-23306292</p>
              <p>沈阳市·浑南区·国际软件园B20-1506</p>
            </div> -->
          </div>
        </div>
        <div class="footer-bottom">
          <div class="footer-bottom-main">
            <div class="footer-bottom-main-info">
              <div class="footer-bottom-main-info-tag">
                <div>专业</div>
                <div class="footer-bottom-main-info-tag-small">MAJOR</div>
              </div>
              <div class="footer-bottom-main-info-tag">
                <div>创新</div>
                <div class="footer-bottom-main-info-tag-small">INNOVATE</div>
              </div>
              <div class="footer-bottom-main-info-tag">
                <div>诚信</div>
                <div class="footer-bottom-main-info-tag-small">SINCERITY</div>
              </div>
              <div class="footer-bottom-main-info-tag">
                <div>担当</div>
                <div class="footer-bottom-main-info-tag-small">UNDERTAKE</div>
              </div>
            </div>
            <div class="footer-bottom-main-triangle"></div>
            <span class="footer-bottom-main-text">COOPERATION +</span>
          </div>
        </div>
      </div>
      <RightMenu />
    </div>
  </div>
</template>
<script>
import locale from "element-ui/lib/locale";
import langEn from "element-ui/lib/locale/lang/en";
import langZh from "element-ui/lib/locale/lang/zh-CN";
import langVi from "element-ui/lib/locale/lang/vi";
import langTw from "element-ui/lib/locale/lang/zh-TW";
// import { v4 as uuidv4 } from "uuid";
import RightMenu from "../components/RightMenu.vue";
export default {
  components: { RightMenu },
  data() {
    return {
      headerbg: true,
    };
  },
  head() {
    return {
      title: this.$t("seo.title"),
    };
  },
  created() {
    let rule = this.$route.path;
    this.setIndex(rule);
    // if (this.$route.path === '/product/product') {
    //   this.headerbg = true
    // } else {
    //   this.headerbg = false
    // }
  },
  mounted() {
    this.initSessionUuid();
  },
  watch: {
    $route(to) {
      let rule = to.path;
      this.setIndex(rule);
      const elementLang = {
        zh: langZh,
        en: langEn,
        vi: langVi,
        tw: langTw,
      };
      locale.use(elementLang[this.$i18n.locale]);
    },
    // '$route.path':function(newVal,oldVal){
    //     //console.log(newVal+"---"+oldVal);
    //     if(newVal === '/product/product'){
    //       this.headerbg = true
    //     } else {
    //       this.headerbg = false

    //     }
    //   }
  },
  data() {
    return {
      nowIndex: 0,
      lang: [],
      headerbg: false,
      title: [
        {
          title: "首页",
          path: "/",
          active: false,
          id: 0,
        },
        // {
        //   title: "header.service",
        //   path: "/service/service",
        //   active: false,
        //   id: 1,
        // },
        {
          title: "header.product",
          path: "/product/product",
          active: false,
          id: 1,
        },
        {
          title: "header.about",
          path: "/about/about",
          active: false,
          id: 2,
        },
        // {
        //   title: "资讯",
        //   path: "/information/information",
        //   active: false,
        //   id: 4,
        // },
        // {
        //   title: "header.contact",
        //   path: "/contact/contact",
        //   active: false,
        //   id: 4,
        // },
      ],
      form: {
        name: "",
        phone: "",
        content: "",
      },
    };
  },
  computed: {
    showLocales() {
      return this.$i18n.locales;
    },
  },
  methods: {
    fireFoxDialogStyle() {
      return navigator.userAgent.indexOf("Firefox") > 0
        ? {
            top: "auto",
            bottom: "60vh",
          }
        : {};
    },
    setIndex(rule) {
      console.log(rule);
      if (rule === "/") {
        this.nowIndex = 0;
      }
      if (rule.includes("/service")) {
        this.nowIndex = 1;
      }
      if (rule.includes("/product")) {
        this.nowIndex = 1;
      }
      if (rule.includes("/about")) {
        this.nowIndex = 2;
      }
      // if (rule.includes("/consult")) {
      //   this.nowIndex = 4;
      // }
      if (rule.includes("/contact")) {
        this.nowIndex = 4;
      }
    },
    handleGoPage(item, inx) {
      this.nowIndex = inx;
      const path = `${
        this.$store.state.lang === "zh" ? "" : "/" + this.$store.state.lang
      }${item.path}`;
      this.$router.push({
        path: path,
      });
    },
    switchLang(lang) {
      this.$store.commit("SET_LANG", lang);
    },
    /**
     * 初始化vuex uuid的值，该值需要在请求时放到头部
     */
    initSessionUuid() {
      if (!window.sessionStorage.getItem("uuid")) {
        const str = `${this.uuid()}`;
        window.sessionStorage.setItem("uuid", str);
      }
    },
    // 生成 uuid
    uuid() {
      // 生成随机字符串
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      // 拼接
      return (
        S4() +
        S4() +
        "" +
        S4() +
        "" +
        S4() +
        "" +
        S4() +
        "" +
        S4() +
        S4() +
        S4()
      );
    },
    /**
     * 提交底部用户需求信息
     */
    async saveInfoHandle() {
      if (!this.form.name) {
        this.$customerMsg().warning("请填写您的姓名");
        return;
      } else if (!this.form.phone) {
        this.$customerMsg().warning("请填写您的联系方式");
        return;
      } else if (!this.form.content) {
        this.$customerMsg().warning("请填写您的需求");
        return;
      }
      const param = {
        CustomerName_: this.form.name,
        CustomerPhone_: this.form.phone,
        CustomerRequire_: this.form.content,
      };
      const { data, status } = await this.$axios.post(
        "/recurit-web/CustomerMessageController/add",
        param
      );
      if (status === 200) {
        this.$customerMsg().success("提交成功");
      } else {
        this.$customerMsg().error(data.message);
      }
      this.form = {
        name: "",
        phone: "",
        content: "",
      };
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  min-width: 1700px;
  overflow: auto;
}
.header {
  height: 70px;
  width: 100%;
  min-width: 1700px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 2000;
  // background-color: #fff;
  .title {
    height: 70px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navli {
      border-bottom: 6px solid #0081ff;
      color: #0081ff !important;
      transition: all 0.5s;
    }
    li {
      padding: 20px 30px;
      color: #707070;
      font-size: 17px;
      text-align: center;
      // line-height: 70px;
      position: relative;
      font-weight: 600;
      cursor: pointer;
      list-style: none;
      .bottom {
        width: 60px;
        height: 4px;
        background: #0081ff;
        border-radius: 60px;
        margin-top: 12px;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    span.active {
      color: #0081ff;
      position: relative;
      top: -4px;
    }
  }
  .right {
    width: 315px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    ul {
      width: 278px;
      height: 50px;
      background: #f7f7f7;
      border-radius: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 75px;
        height: 42px;
        border-radius: 80px;
        line-height: 42px;
        text-align: center;
        font-size: 15px;
        color: #707070;
        cursor: pointer;
      }
      .active {
        background: #0081ff;
        a {
          color: #ffffff;
        }
      }
    }
  }
}
.bg {
  background-color: rgba(255, 255, 255, 0.1);
}
.header1 {
  height: 70px;
  width: 100%;
}
.content__view {
  min-height: calc(100vh - 367px - 70px);
  min-width: 1700px;
  margin: 0 auto;
  // overflow: auto;
  position: relative;
}
.footer {
  height: 367px;
  &-top {
    background-color: #3070ef;
    background-image: url(/img/footer/footer_bcg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 14px;
    &-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 132px;
      padding: 0 330px;
      &-logo {
        width: 186px;
        height: 57px;
      }
      &-info {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        &-input {
          display: flex;
          align-items: center;
          margin-top: 8px;
          &-emit {
            width: 122px;
            height: 40px;
            line-height: 40px;
            margin-left: 14px;
            background: #ffffff;
            border-radius: 2px;
            text-align: center;
            font-weight: 500;
            color: #0081ff;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
          /deep/ .el-input {
            width: 216px;
            height: 42px;
            margin-right: 10px;
            border-radius: 2px;
          }
          /deep/ .el-input__inner {
            height: 42px;
            line-height: 42px;
            border-radius: 2px;
            border: 1px solid #1953bd !important;
            background: rgba(25, 83, 189, 0.5);
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    &-line {
      height: 1px;
      background: #ffffff;
      opacity: 0.3;
    }
    &-address {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      padding: 0 330px;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 10px;
      }
    }
  }
  &-bottom {
    position: relative;
    height: 150px;
    background-image: url(/img/footer/footer_bcg2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &-main {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      &-info {
        display: flex;
        align-items: center;
        width: 660px;
        height: 100%;
        padding-left: 330px;
        background: #1953bd;
        &-tag {
          font-size: 40px;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
          &-small {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 400;
          }
          &:not(:last-child) {
            margin-right: 80px;
          }
        }
      }
      &-triangle {
        width: 0;
        height: 0;
        border-right: 100px solid transparent;
        border-bottom: 150px solid #1953bd;
      }
      &-text {
        flex: 1;
        text-align: center;
        line-height: 120px;
        font-size: 44px;
        font-family: DINPro-Bold, DINPro;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
a {
  text-decoration: none;
  color: #1d2025;
}
.shiyan {
  color: red;
}
</style>
